<div class="content">
    <section class="section">
        <div class="main">
            <div class="quote-form">
                <div class="quote-wrapper">
                    @if (!iframeLoaded) {
                        <div [@loading] class="loading getAQuote">
                            <img draggable="false" class="logo" src="../assets/Other/Logo-black.svg"  alt="loading">
                            <img draggable="false" class="circle" src="../assets/Other/Loading-circle-white.webp"  alt="loading">
                        </div>
                    }
                    @if (linkLoaded) {
                        <iframe 
                        #iframeForm
                        title="Get A Quote"
                        (load)="iframeLoadedHandle($event)"
                        [src]="link"
                        class="getAQuote" 
                        style="border-width: 0;" 
                        data-referenceid="5Wd_O7HUukC" 
                        data-source="DieselDispatch" 
                        data-onsuccess="thankyou"
                        data-supportphone="4803601265"
                        [attr.data-url]="dataUrl"
                        data-gtm-yt-inspected-7="true">
                        </iframe>
                    }
                </div>
            </div>
        </div>
    </section>
</div>
